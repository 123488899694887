.footer {
  padding: 20px 0px 80px 30px;
  background: #273e5e;
}

.footer a {
  color: white;
  font-size: 20px;
  margin-right: 25px;
  text-decoration: none;
}

.footer a:hover {
  color: rgb(100, 99, 99);
}
