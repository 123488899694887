.blog-page .main-content {
  padding: 4vh 5vw;
}

.blog-page .main-content img {
  width: 100%;
}

.blog-page .main-content .title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
