.all-podcasts {
  padding: 3vh 0 0 3vw;
  box-sizing: border-box;
}

.all-podcasts .main-title {
  font-size: calc(10px + 0.8vw);
  font-weight: bold;
  color: #424242;
  margin-bottom: 20px;
}

.all-podcasts article {
  color: gray;
  margin-bottom: 15px;
  font-size: calc(10px + 0.8vw);
}

.all-podcasts article .title {
  color: gray;
  text-decoration: underline;
}
