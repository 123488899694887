#ContactUs article {
  position: relative;
}

.map {
  padding-top: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

#ContactUs article .left .title {
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 15px;
}

#ContactUs article .left .content {
  font-size: 16px;
}

#ContactUs article .form .title {
  font-size: 18px;
  margin-bottom: 10px;
  padding-right: 30px;
}

#ContactUs article .form .form-box {
  flex: 1 1 45%;
  width: 100%;
}

#ContactUs article .form .form-box-full {
  flex: 1 1 100%;
  width: 100%;
}

#ContactUs article .form .name {
  float: left;
  padding-right: 30px;
}

#ContactUs article .form .email {
  float: right;
  padding-right: 30px;
}

#ContactUs article .form .message {
  padding-right: 30px;
}

#ContactUs article .form .send {
  float: right;
}
