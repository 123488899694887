.search .main-content {
  padding: 2vh 2vw;
}

.search .page-header {
  font-size: 22px;
  font-weight: bold;
  padding-left: 20px;
  line-height: 40px;
}

.search article.search-content {
  padding: 0 25px;
  margin-bottom: 25px;
  padding-left: 10px;
}

.search article.search-content a {
  text-decoration: none;
}

.search article.search-content .title {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.search article.search-content .content {
  font-size: 18px;
  color: black;
}
