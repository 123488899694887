@font-face {
    font-family: Graphik Regular;
    src: url(Graphik-Regular.ttf);
}

body {
    background: #273e5e;
}

.background {
    font-family: Graphik Regular;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.63;
    background: #273e5e;
}

.container {
    background-color: white;
    display: table;
    width: 100%;
}

.description {
    height: calc(30px + 8vh);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: calc(17px + 0.8vw);
    background-color: white;
    padding: 0 30px;
}

.main-box {
    height: auto;
}

.blogText {
    display: block;
    background-color: white;
    padding: 20px;
    overflow-y: hidden;
    --lh: 1.63rem;
    --max-lines: 15;
    line-height: var(--lh);
    height: calc(var(--lh) * var(--max-lines));
}

.VBPTitle {
    text-align: center;
    width: 100%;
    padding-top: 25px;
    margin: auto;

    height: 100px;
    font-weight: bold;

    font-size: 30px;
}

.featured-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 3vw;
    padding-bottom: 1vh;
    height: auto;
    background-color: #eff0ef;
    overflow: hidden;
}

.featured-item-header {
    display: grid;
}

.flex-item {
    padding: 20px;
    background-color: #eff0ef;
    flex: 1 1 30%;
    min-width: 300px;
}

.flex-item .title {
    font-weight: bold;
    line-height: 40px;
}

.flex-item img {
    width: 100%;
}

.flex-item .more {
    text-align: center;
    background-color: white;
    padding-bottom: 10px;

    text-decoration: underline;

    color: black;
}

.BrandText .more {
    text-decoration: underline;
    line-height: 50px;
    font-size: 20px;
}

.notification {
    padding: 18px;
    text-align: center;
    height: 30px;
}

.notification a {
    text-decoration: none;
    color: #314070;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.down:hover {
    color: rgb(100, 99, 99);
    text-decoration: underline;
}

.brand-image {
    position: relative;
    text-align: center;
    color: white;
}

.brand-image .image {
    display: grid;
    position: relative;
    max-width: 100%;
    max-height: 100%;
}

.brand-image img {
    max-width: 100%;
    max-height: 100%;
}

.brand-image .brand-text .title {
    text-align: center;
    font-size: calc(18px + 0.8vw);
    width: 100%;
}

.brand-image .brand-text .desc {
    font-size: calc(6px + 1vw);
    width: 100%;
}

.brand-image .brand-text-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.brand-image .brand-text-container .brand-text {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadein 3s;
    width: 80vw;
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.service-flex {
    display: flex;
    flex-wrap: wrap;
}

.service-box {
    flex: 1 1 350px;
    position: relative;
}

.content-box {
    flex: 1 2 70%;
}

.side-box {
    flex: 1 1 20%;
    background-color: #eff0ef;
}

.side-box .recentPosts .recentPostItem a {
    text-decoration: none;
}

.firstRow span {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.services-type.first,
.services-type.second,
.services-type.third,
.services-type.fourth {
    background-size: cover;
    height: 50vh;
    font-size: 5vmin;
    max-width: 100%;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

#homepage {
    height: 100%;
}

#Services {
    height: 100%;
    padding-left: 20px;
}

.image-box {
    position: relative;
    float: right;
    width: 40%;
    text-align: center;
    margin: 0px 0px 10px 20px;
}

.about {
    width: 90%;
    margin: auto;
}

.about .left {
    width: 33%;
    float: left;
    padding: 25px 40px;
    font-size: 25px;
}
.about .main {
    width: 67%;
    float: left;
    padding-top: 1em;
}

#homepage .reactPlayer-container {
    position: relative;
    padding-top: 56.25%;
}

#homepage .reactPlayer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
}

.ant-layout-header {
    padding: 0px;
}

.ant-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.main-content .content {
    line-height: 40px;
    padding: 0 20px;
    position: relative;
}

.main-content .MuiButton-containedPrimary {
    background-color: #273e5e;
}
