.career {
  padding: 48px 80px;
  font-size: 16px;
  box-sizing: border-box;
}

.career .title {
  line-height: 40px;
  font-size: 20px;
  color: #3d838f;
}

.career .date {
  font-size: 14px;
}

.career .content {
  color: gray;
}

.career .content ul {
  list-style-type: none;
  padding-inline-start: 20px;
}

.career .content ul li svg {
  margin-right: 8px;
  font-size: small;
  color: cadetblue;
}

.career h3.form-header {
  font-weight: bold;
  color: #3d838f;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.career .category .ant-col {
  padding: 0.857em 0.587em;
  border: 1px solid #dadada;
  border-right: none;
  padding-left: 20px;
}

.career .category.ant-row {
  border-right: 1px solid #dadada;
}

.career .ant-row.ant-form-item {
  width: 100%;
}

.career .ant-col.ant-form-item-label {
  text-align: left;
  padding-left: 30px;
}

.career .ant-col.ant-form-item-control {
  width: 80%;
}

.career .detail .ant-col {
  color: #3d838f;
  margin: 25px 0;
}

.career .detail .jobType,
.career .detail .location,
.career .detail .date {
  float: left;
  padding: 0 25px;
}

.career .detail .jobTitle,
.career .detail .jobType,
.career .detail .location,
.career .detail .date {
  height: 100%;
  display: flex;
  align-items: center;
}

.career .detail svg {
  margin-right: 10px;
}
