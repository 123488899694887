.comment-main {
  margin-top: 30px;
}

.comment-main .comment {
  margin-left: 40px;
  margin-bottom: 30px;
}

.comment-main .comment .comment-header .name {
  font-weight: bold;
}

.comment-main .comment .comment-header .date {
  color: #bdbdbd;
}

.comment-main .comment .comment-body {
}

.comment-main .submit-post {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 20px;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  -webkit-box-shadow: inset 0 1px 2px rgb(255 255 255 / 50%);
  -moz-box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 1px 2px rgb(255 255 255 / 50%);
  height: 2rem;
  background-color: #273e5e;
}
