.video .main-content img {
  width: 100%;
}

.video .main-content {
  width: 100%;
  float: left;
  padding: 4vh 5vw;
  background-color: #eff0ef;
}

.video .right-content {
  width: 25%;
  float: right;
  padding: 15px;
}

.video .main-content .title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  background-color: #eff0ef;
}

.video .reactPlayer {
  aspect-ratio: 16/9;
}
