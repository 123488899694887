.careers .header {
    text-align: center;
    padding: 0px 0px;
    background-color: white;
}

.careers .header h1 {
}

.careers .header h1,
.careers .main-box {
    padding: 2vh 4vw;
}

.careers .main-box .title {
    font-size: 3em;
}

.careers .main-box .content {
    font-size: 18px;
}

.careers .list {
    padding-top: 15px;
}

.careers .list .item {
    border: 1px solid #dadada;
    border-radius: 3px;
    color: #3d838f;
    margin: 25px 0;
}

.careers .list .item span {
    align-items: center;
}

.careers .list .item .jobTitle a {
    float: left;
    padding: 0 35px;
    font-size: 18px;
    color: #3d838f;
    text-decoration: none;
}

.careers .list .item .jobType,
.careers .list .item .location,
.careers .list .item .date {
    float: left;
    padding: 0 25px;
}

.careers .list .item .jobTitle,
.careers .list .item .jobType,
.careers .list .item .location,
.careers .list .item .date {
    display: flex;
    align-items: center;
}

.careers .list .item svg {
    margin-right: 10px;
}
.marketing {
    background-color: #273e5e;
}
.marketingImg {
    background-color: #273e5e;
    margin: 0 2vw;
}
.marketingImg img {
    max-width: 80px;
    background-color: #273e5e;
    width: 80px;
    margin-right: auto;
}
.marketingText {
    color: white;
    font-size: calc(15px + 0.4vw);
    text-align: center;
    background-color: #273e5e;
    width: 100%;
}

.services-type .boxText,
.services-type .mobileBoxText {
    white-space: pre-wrap;
}
