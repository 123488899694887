.searchGroup .main-content {
  padding: 2vh 2vw;
}

.searchGroup article section.groupHeader {
  font-size: 30px;
  font-weight: bold;
  padding: 10px 0;
  padding-left: 30px;
}

.searchGroup article section.groupFooter {
  font-size: 20px;
  font-weight: bold;
  color: gray;
  text-align: center;
}

.searchGroup article section.groupFooter a {
  color: gray;
}

.searchGroup article .search-content {
  padding: 0 25px;
  margin-bottom: 25px;
}

.searchGroup article .search-content a {
  text-decoration: none;
}

.searchGroup article .search-content .title {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.searchGroup article .search-content .content {
  font-size: 18px;
  color: black;
}
