.capital-projects-home .firstRow {
    height: 60px;
    background-color: white;
    font-weight: bold;
    font-size: 20px;
}

.capital-projects-home .services-type {
    font-weight: bold;
    color: white;
    font-size: 35px;
}

.capital-projects-home .services-type.first,
.capital-projects-home .services-type.second,
.capital-projects-home .services-type.third,
.capital-projects-home .services-type.fourth {
    background-size: cover;
    background-repeat: no-repeat;
    height: 700px;
}
.capital-projects-home .services-type.first {
    background-image: url('../../assets/images/Services/9.jpg');
}
.capital-projects-home .services-type.second {
    background-image: url('../../assets/images/Services/10.jpg');
}
.capital-projects-home .services-type.third {
    background-image: url('../../assets/images/Services/11.jpg');
}
.capital-projects-home .services-type.fourth {
    background-image: url('../../assets/images/Services/12.jpg');
}
.capital-projects-home .text {
    font-size: 18px;
}
.capital-projects-home .textTitle {
    font-size: 20px;
}
