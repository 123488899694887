


.author {
  clear: both;
  width: 100%;
  margin-top: 10px;
  
}

.author .authorItem,
.recentPosts .recentPostItem {
  margin-bottom: 15px;
}

.author .header,
.recentPosts .header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
  padding-top: 20px;



}

.author .authorImage {
  
  width:60px;
  height: 60px;

  float: left;
  margin-right: 10px;
  
}

.author .name {
  line-height: 60px;
  font-weight: bold;
  color: Black;


}

.recentPosts .title {
  font-weight: bold;
  color: black;

  
}

.recentPosts .date {
  font-size: 12px;
  color: black;
  
}
