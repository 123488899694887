.services-home .firstRow {
    height: 80px;
    background-color: #273e5e;
    color: white;
}

.services-home .services-type.first,
.services-home .services-type.second,
.services-home .services-type.third {
    background-size: cover;
    height: 50vh;
    font-size: 5vmin;
    max-width: 100%;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.services-home .services-type.first {
    background-image: url('../../assets/images/Home/Capital.jpg');
}
.services-home .services-type.second {
    background-image: url('../../assets/images/Home/Readiness.jpg');
}
.services-home .services-type.third {
    background-image: url('../../assets/images/Home/Performance.jpg');
}

.services-home .service-box a {
    text-decoration: none;
}

.services-type {
    height: 100px;
    font-weight: bold;
    font-size: 20px;
    color: white;
}
.marketing {
    background-color: #273e5e;
}
.marketingImg {
    background-color: #273e5e;
    margin: 0 2vw;
}
.marketingImg img {
    max-width: 80px;
    background-color: #273e5e;
    width: 80px;
    margin-right: auto;
}
.marketingText {
    color: white;
    font-size: calc(15px + 0.4vw);
    text-align: center;
    background-color: #273e5e;
    width: 100%;
}

.services-type .boxText,
.services-type .mobileBoxText {
    white-space: pre-wrap;
}

.services-type:hover .boxText,
.services-type:hover .mobileBoxText.boxTextClick {
    color: transparent;
}

.services-type:hover .overlay,
.services-type:hover .mobileOverlay.boxTextClick {
    height: 100%;
}

.overlay,
.mobileOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(39, 62, 94, 0.6);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
}

.mobileOverlay .clear {
    position: absolute;
    top: 10px;
    right: 10px;
}
.textTitle {
    position: relative;
    color: white;
    font-size: calc(10px + 0.3vw);
    padding-left: 20px;
    padding-right: 20px;
    left: 50%;
    top: 10%;
    font-weight: bolder;

    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.text {
    position: relative;
    color: white;
    font-size: calc(10px + 0.3vw);
    padding-left: 20px;
    padding-right: 20px;
    left: 50%;
    top: 50%;
    font-weight: normal;

    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
